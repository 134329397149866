import { apiBaseUrl } from "../config";
import { getModeratorAuthorization, signOutModerator } from "./authUtils";
import { toast } from "react-toastify";

const authorizationHeader = "Authorization";

export function unauthenticatedApiRequest(
  path,
  { body, options, method } = {}
) {
  let combinedOptions = {};
  combinedOptions.method = method || "GET";

  options = options || {};
  combinedOptions.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(options.headers || {}),
  };

  if (body) {
    combinedOptions.body = JSON.stringify(body);
  }

  const urlWithPath = new URL(apiBaseUrl + path);
  urlWithPath.search = new URLSearchParams(options.params).toString();

  return new Promise((resolve, reject) => {
    fetch(urlWithPath.toString(), combinedOptions)
      .then((r) => {
        const basicResponse = { status: r.status, headers: r.headers };
        return r
          .json()
          .then((data) => ({ ...basicResponse, body: data }))
          .catch(() => basicResponse);
      })
      .then(({ status, body, headers }) => {
        if (status >= 200 && status < 300) {
          resolve({ body, headers });
        } else if (status === 401) {
          signOutModerator();
          reject({ body, headers });
        } else {
          reject({ body, headers });
        }
      })
      .catch((err) => reject(err));
  });
}

export function apiRequest(path, { body, options, method } = {}) {
  options = options || {};
  options.headers = options.headers || {};
  options.headers[authorizationHeader] = getModeratorAuthorization();

  return unauthenticatedApiRequest(path, { body, options, method });
}

export function sourceAppApiRequest(
  sourceApp,
  path,
  { body, options, method } = {}
) {
  return apiRequest(path, {
    body,
    options: {
      ...options,
      headers: {
        "Source-App": sourceApp,
      },
    },
    method,
  });
}

export function toastErrors(errorBody = {}) {
  const errors = errorBody.errors || ["Something went wrong"]
  errors.forEach(toast.error)
}
