import { setItemToStore, getItemFromStore } from "./utils";

const authorizationKey = "currentModeratorAuthorization";
const currentModeratorKey = "currentModerator";

export function signOutModerator() {
  setItemToStore(authorizationKey, null);
  setItemToStore(currentModeratorKey, null);
}

export function setModeratorAuthorization(authorizationPayload) {
  setItemToStore(authorizationKey, authorizationPayload);
}

export function setCurrentModerator(moderator) {
  setItemToStore(currentModeratorKey, moderator);
}

export function getModeratorAuthorization() {
  return getItemFromStore(authorizationKey);
}

export function isModeratorSignedIn() {
  return Boolean(getModeratorAuthorization());
}

export function getCurrentModerator() {
  return getItemFromStore(currentModeratorKey);
}
