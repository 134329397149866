import { apiRequest, unauthenticatedApiRequest } from "./apiUtils";
import {
  setModeratorAuthorization,
  setCurrentModerator,
  signOutModerator,
} from "./authUtils";

const authorizationHeader = "Authorization";

const signInPath = "/api/auth/sign_in";
const forgetPasswordPath = "/api/auth/password";
const passwordPath = "/api/auth/passwords";
const confirmInvitationPath = "/api/auth/invitation";
const currentModeratorPath = "/api/v1/current_moderator";

export function forgetPassword({ email }) {
  return unauthenticatedApiRequest(forgetPasswordPath, {
    method: "POST",
    body: { moderator: { email } },
  });
}

export function resetPasswordModerator({
  password,
  confirmPassword,
  resetPasswordToken,
}) {
  return unauthenticatedApiRequest(forgetPasswordPath, {
    method: "PUT",
    body: {
      moderator: {
        password: password,
        password_confirmation: confirmPassword,
        reset_password_token: resetPasswordToken,
      },
    },
  });
}

export function changePasswordModerator({
  password,
  confirmPassword,
  currentPassword,
}) {
  return apiRequest(passwordPath, {
    method: "PUT",
    body: {
      moderator: {
        password: password,
        password_confirmation: confirmPassword,
        current_password: currentPassword,
      },
    },
  });
}

export function confirmInvitationModerator({
  password,
  confirmPassword,
  invitationToken,
}) {
  return unauthenticatedApiRequest(confirmInvitationPath, {
    method: "PUT",
    body: {
      moderator: {
        password: password,
        password_confirmation: confirmPassword,
        invitation_token: invitationToken,
      },
    },
  });
}

export function signInModerator({ email, password }) {
  return new Promise((resolve, reject) => {
    unauthenticatedApiRequest(signInPath, {
      method: "POST",
      body: { moderator: { email, password } },
    })
      .then(({ body, headers }) => {
        const authorizationPayload = headers.get(authorizationHeader);
        setModeratorAuthorization(authorizationPayload);
        setCurrentModerator(body);
        resolve(body);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
}

export function reloadModerator() {
  return new Promise((resolve, reject) => {
    apiRequest(currentModeratorPath)
      .then(({ body }) => {
        setCurrentModerator(body);
        resolve(body);
      })
      .catch((err) => {
        signOutModerator();
        console.error(err);
        reject();
      });
  });
}
