import { setItemToStore, getItemFromStore } from "./utils";

const availablePuppetsKey = "availablePuppets";
const selectedPuppetKey = "selectedPuppet";

export function restoreAvailablePuppets() {
  return getItemFromStore(availablePuppetsKey, [])
}

export function storeSelectedPuppet(option) {
  setItemToStore(selectedPuppetKey, option);
}

export function storeAvailablePuppets(options) {
  setItemToStore(availablePuppetsKey, options);
}

export function restoreSelectedPuppet() {
  const previousOption = getItemFromStore(selectedPuppetKey, null);
  const availablePuppets = restoreAvailablePuppets();

  if (availablePuppets.length === 0) return null;
  if (availablePuppets.find(o => o === previousOption)) return previousOption;

  storeSelectedPuppet(null)
  return null;
}
