export const homeRoutes = {
  name: "Dashboard",
  to: "/",
  icon: "bar_chart",
};

export const resourceRoutes = {
  name: "Resources",
  to: "/",
  icon: "list",
  children: [
    {
      to: "/apple_products",
      name: "Apple Products",
    },
    {
      to: "/devices",
      name: "Devices",
    },
    {
      to: "/gdpr_tickets",
      name: "GDPR tickets",
    },
    {
      to: "/subscriptions",
      name: "Subscriptions",
    },
    {
      to: "/subscription_events",
      name: "Subscription Events",
    },
    {
      to: "/users",
      name: "Users",
    },
  ],
};

export const moderationRoutes = {
  name: "Moderation",
  to: "/moderation",
  icon: "construction",
  children: [
    {
      to: "/moderation/moderators",
      name: "Moderators",
    },
    {
      to: "/moderation/applications",
      name: "Applications",
    },
  ],
};

export default [homeRoutes, resourceRoutes, moderationRoutes];
