import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import loadable from "@loadable/component";

import { Fade } from "../components/common/Toast";
import DashboardLayout from "./DashboardLayout";
import ErrorLayout from "./ErrorLayout";

const AuthBasicLayout = loadable(() => import("./AuthBasicLayout"));

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
  }, []);

  return (
    <>
      <Routes>
        <Route path="errors/*" element={<ErrorLayout />}/>
        <Route path="auth/*" element={<AuthBasicLayout />}/>
        <Route path="*" element={<DashboardLayout />}/>
      </Routes>
      <ToastContainer
        transition={Fade}
        position={toast.POSITION.BOTTOM_RIGHT}
      />
    </>
  );
};

export default Layout;
