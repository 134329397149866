export const version = document.body.dataset.codeRevision;
export const navbarBreakPoint = "xl"; // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg";
export const apiBaseUrl = document.body.dataset.internalApiUrl;
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isNavbarVerticalCollapsed: false,
  navbarStyle: "card",
};
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
