import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import loadable from "@loadable/component";
import { toast } from "react-toastify";

import { Dashboard } from "../components/dashboard/Dashboard";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import Footer from "../components/footer/Footer";
import { AppContext, AuthContext, PuppetContext } from "../context/Context";
import { reloadModerator } from "../helpers/authApiUtils";
import { restoreAvailablePuppets, restoreSelectedPuppet } from "../helpers/puppetContextUtils";

const DashboardRoutes = loadable(() => import("./DashboardRoutes"));

const DashboardLayout = () => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const { isSignedIn, setCurrentModerator } =
    useContext(AuthContext);
  const [selectedPuppet, setSelectedPuppet] = useState(restoreSelectedPuppet());
  const [availablePuppets, setAvailablePuppets] = useState(restoreAvailablePuppets());

  const location = useLocation()

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (!isSignedIn) {
    return (
      <Routes>
        <Route
          path='*'
          element={<Navigate
            to={{
              pathname: "/auth/login",
              state: { referrer: location },
            }}
          />}
        />
      </Routes>
    );
  }

  useEffect(() => {
    // there should be global spinner or smth in background
    isSignedIn &&
      reloadModerator()
        .then((body) => setCurrentModerator(body))
        .catch((obj) => {
          toast.error(obj ? obj.error : "Something happened wrong.");
          // TODO: this should redirect to auth login too
          // setIsSignedIn(false)
          // setCurrentModerator(null)
        });
  }, []);

  const puppetValues = {
    selectedPuppet,
    setSelectedPuppet,
    availablePuppets,
    setAvailablePuppets
  }

  return (
    <div className={isFluid ? "container-fluid h-100" : "container h-100"} style={{ minHeight: "100%" }}>
      {isVertical && <NavbarVertical navbarStyle={navbarStyle} />}
      <div className="content d-flex flex-column h-100">
        <PuppetContext.Provider value={puppetValues}>
          <NavbarTop />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="*" element={<DashboardRoutes />} />
          </Routes>
          <Footer />
        </PuppetContext.Provider>
      </div>
    </div>
  );
};

export default DashboardLayout;
