import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Col, Row } from "reactstrap";

import Error404 from "../components/errors/Error404";
import Error500 from "../../../../../vendor/falcon-react-v2.10.1/src/components/errors/Error500";
import Logo from "../components/navbar/Logo";
import Section from "../../../../../vendor/falcon-react-v2.10.1/src/components/common/Section";

const ErrorLayout = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo/>
          <Routes>
            <Route path='404' element={<Error404/>}/>
            <Route path='500' element={<Error500/>}/>

            {/*Navigate*/}
            <Route element={<Navigate to='404'/>}/>
          </Routes>
        </Col>
      </Row>
    </Section>
  );
}

export default ErrorLayout;
