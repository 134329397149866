import React from "react";
import PropTypes from "prop-types";

const MaterialIcon = ({ className, name, style, onClick }) => {
  const classNames = [className, "material-icons"].join(" ");

  return (
    <span className={classNames} style={{ style }} onClick={onClick}>
      {name}
    </span>
  );
};

MaterialIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

MaterialIcon.defaultProps = {
  className: "",
  style: {},
};

export default MaterialIcon;
