import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import App from "./dashboard/App";
import Main from "./dashboard/Main";

const Dashboard = ({ name }) => <div>Dashboard {name}!</div>;

Dashboard.defaultProps = {
  name: "David",
};

Dashboard.propTypes = {
  name: PropTypes.string,
};

export default Dashboard;

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Main>
      <App />
    </Main>,
    document.getElementById("main")
  );
});
