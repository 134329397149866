import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

import MaterialIcon from "../common/MaterialIcon";
import Flex from "../../../../../../vendor/falcon-react-v2.10.1/src/components/common/Flex";

const NavbarVerticalMenuItem = ({ route }) => (
  <Flex inline>
    {route.icon && (
      <span className="nav-link-icon">
        <MaterialIcon name={route.icon} className="md-18" />
      </span>
    )}
    <span className="nav-link-text">{route.name}</span>
    {!!route.badge && (
      <Badge color={route.badge.color || "soft-success"} pill className="ml-2">
        {route.badge.text}
      </Badge>
    )}
  </Flex>
);

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(NavbarVerticalMenuItem);
