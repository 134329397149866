import React from "react";
import { Col, Row } from "reactstrap";

import { version } from "../../config";

const Footer = () => (
  <footer>
    <Row
      noGutters
      className="justify-content-between text-center fs--1 mt-4 mb-3"
    >
      <Col sm="auto">
        <p className="mb-0 text-600">
          This is an internal tool. Don't share anything outside of it
          <span className="d-none d-sm-inline-block ml-1 mr-1">|</span>
          <br className="d-sm-none" />For help please contact administrators
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">Version: {version}</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
